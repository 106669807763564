import Vue from "vue";
import i18n from './i18n'
import Router from 'vue-router';

Vue.use(Router)

export default new Router({
    mode: 'history',

    routes: [
        {
            path: '/subscription',
            name: 'subscription',
            meta: { title: 'Invoxia - ' + i18n.t('subscription') },
            component: () => import("./pages/SubscriptionPage.vue")
        },
        {
            path: '/subscriptionSelector',
            name: 'subscriptionSelector',
            meta: { title: 'Invoxia - ' + i18n.t('subscriptionSelector') },
            component: () => import("@/components/subscription/SubscriptionSelector.vue")
        },
        {
            path: '/login',
            alias: ['/'],
            name: 'login',
            meta: { title: 'Invoxia - ' + i18n.t('login_signin') },
            component: () => import("./pages/LoginPage.vue")
        },
        {
            path: '/error',
            name: 'error',
            meta: { title: 'Invoxia - ' + i18n.t('error') },
            component: () => import("./pages/Error.vue")
        },
        {
            path: '*',
            name: '404',
            meta: { title: 'Invoxia - ' + i18n.t('error_404') },
            component: () => import("./pages/Error404.vue")
        }
    ]
});