import Vue from 'vue'
import i18n from './i18n'
import router from './router'

import App from './App.vue'
import store from './store/store'

import './vue-filters.js'

import BootstrapVue from "bootstrap-vue"
import VueApexCharts from 'vue-apexcharts'

Vue.use(BootstrapVue);
Vue.use(VueApexCharts);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/scss/style.scss'

Vue.config.productionTip = false
Vue.config.delimiters = ['${', '}'];

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
});

export var vm = new Vue({
  render: h => h(App),
  i18n,
  router,
  store,
}).$mount('#app')

window.app = vm
