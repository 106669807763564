import httpClient from './httpClient';

const END_POINT_TOKEN = '/api/v1/auth/web_get_token/';
const END_POINT_LOGIN = '/api-auth/session/login/';
const END_POINT_PAIEMENTSESSION = '/users/payment_session/';
const END_POINT_OPTIONS = '/options/';

//add this and remove login_data in postUserData to a direct access
// let login_data = { email: 'jean-baptiste.choquet@invoxia.com', password: 'password', };

const postUserData = (user_data) => httpClient.post(END_POINT_TOKEN, user_data);

const getUserLogin = () => httpClient.get(END_POINT_LOGIN);

const getUserOptions = () => httpClient.get(END_POINT_OPTIONS);

const postPaiementSession = (paiement_data) => httpClient.post(END_POINT_PAIEMENTSESSION, paiement_data);

export {
    postUserData,
    getUserLogin,
    getUserOptions,
    postPaiementSession,
}