import { getAllSubscriptions } from "../../api/subscriptions.api"

const state = {
    subscriptions: [],
    selectedSubscription: null,
}

const getters = {
    getSubscriptions(state) {
        return state.subscriptions;
    },
    getSelectedSubscription(state) {
        return state.selectedSubscription;
    },
}

const actions = {
    async fetchSubscriptions({ commit }) {
            try {
                const response = await getAllSubscriptions();
                commit('SET_SUBSCRIPTIONS', response.data);
                commit('SET_SELECTEDSUBSCRIPTION', response.data[0]);
            } catch (error) {
                console.log(error);
            }    
    },
    async setSelectedSubscription({ commit }, data) {
        commit('SET_SELECTEDSUBSCRIPTION', data);
    }
}

const mutations = {
    SET_SUBSCRIPTIONS(state, data) {
        state.subscriptions = data;
    },
    REMOVE_SUBSCRIPTIONS(state, data) {
        state.subscriptions = [];
    },
    SET_SELECTEDSUBSCRIPTION(state, data) {
        state.selectedSubscription = data;
    },
    REMOVE_SELECTEDSUBSCRIPTION(state) {
        state.selectedSubscription = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}