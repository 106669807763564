import Vue from 'vue';
import Vuex from 'vuex';

import devicesModule from '../store/modules/devices.module'
import subscriptionsModule from './modules/subscriptions.module';
import usersModule from './modules/users.module';
import carouselModule from './modules/carousel.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    devices: devicesModule,
    subscriptions: subscriptionsModule,
    users: usersModule,
    carousel: carouselModule,
  },
})