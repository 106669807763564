import { postUserData, getUserLogin, getUserOptions, postPaiementSession } from "../../api/users.api"
import router from '@/router'

const state = {
    isWebView: false,
    userName: null,
    userRole: null,
    token: localStorage.getItem('token'),
}

const getters = {
    getToken(state) {
        return state.token;
    },
    getUserName(state) {
        return state.userName;
    },
    getUserRole(state) {
        return state.userRole;
    },
    isWebView(state) {
        return state.isWebView;
    }
}

const actions = {
    async fetchUser({ commit }, user_data) {
        try {
            const response = await postUserData(user_data);
            commit('SET_TOKEN', response.data.token);
            router.push('/subscription');
        } catch (error) {
            return error
        }    
    },
    async login({ commit }) {
        try {
            const response = await getUserLogin();
            commit('SET_USERNAME', response.data.user);
        } catch (error) {
            console.log(error);
        }
    },
    async logout({ commit }){
        commit('REMOVE_TOKEN');
        if (window.sessionStorage) {
            sessionStorage.clear();
            console.log('session clear')
        }
        router.push('/login');
    },
    async paiementSession({ commit }, paiementSession_data) {
        try {
            const response = await postPaiementSession(paiementSession_data);
            Stripe(response.data.public_key).redirectToCheckout({sessionId: response.data.id})
        }
        catch (error) {
            console.log(error);
        }
    },
    async fetchUserRole({ commit }) {
        try {
            const response = await getUserOptions();
            commit('SET_USERROLE', response.data.role);
        }
        catch (error) {
            console.log(error);
        }
    },
    async setToken({ commit }, data) {
        commit('SET_TOKEN', data);
    },
    async setIsWebView({ commit }, data) {
        commit('SET_ISWEBVIEW', data)
    }
}

const mutations = {
    SET_ISWEBVIEW(state, data) {
        state.isWebView = data;
    },
    SET_TOKEN(state, data) {
        localStorage.setItem('token', data);
    },
    REMOVE_TOKEN(state, data) {
        localStorage.removeItem('token');
    },
    SET_USERNAME(state, data) {
        state.userName = data;
    },
    REMOVE_USERNAME(state, data) {
        state.userName = null;
    },
    SET_USERROLE(state, data) {
        state.userRole = data;
    },
    REMOVE_USERROLE(state,data) {
        state.userRole = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}