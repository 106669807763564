import { getAllDevices } from "../../api/devices.api"

const state = {
    trackers: [],
    selectedTracker: null,
}

const getters = {
    getDevices(state) {
        return state.trackers;
    },
    getSelectedDevice(state) {
        return state.selectedTracker;
    },
}

const actions = {
    async fetchDevices({ commit }) {
            try {
                const response = await getAllDevices();
                commit('SET_DEVICES', response.data.sort(function(a,b){  
                    if(new Date(a.tracker_status.sub_end_date) > new Date(b.tracker_status.sub_end_date))  
                        return 1;
                    else if(new Date(a.tracker_status.sub_end_date) < new Date(b.tracker_status.sub_end_date))  
                        return -1;  
                    return 0;  
                }));
            } catch (error) {
                console.log(error);
            }    
    },
    async setSelectedDevice({ commit }, data) {
        commit('SET_SELECTEDDEVICE', data);
    },
    async removeDevices({ commit }) {
        commit('REMOVE_DEVICES');
    },
    async removeSelectedDevice({ commit }) {
        commit('REMOVE_SELECTEDDEVICE');
    }
}

const mutations = {
    SET_DEVICES(state, data) {
        state.trackers = data;
    },
    REMOVE_DEVICES(state) {
        state.trackers = [];
    },
    SET_SELECTEDDEVICE(state, data) {
        data.price = { meta_data: {months: 0}};
        state.selectedTracker = data;
    },
    REMOVE_SELECTEDDEVICE(state) {
        state.selectedTracker = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}